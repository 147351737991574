import React from "react";
import { Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/seo/seo";
import Back from "../components/back/back";

import "./template-layout.scss";
import ToTop from "../components/to-top/to-top";

const TemplateLayout = props => {
  const { children, content, nextProject } = props

  let additionalLink
  if (content.link) {
    additionalLink = (
      <p>
        <strong>Development Link:  </strong>
        <a
          rel="noopener noreferrer"
          href={content.link.url}
          title={content.link.title}
          target="_blank"
        >{content.link.url}
        </a>
      </p>
    )
  }

  return (
    <Layout>
      <div className="details">
        <SEO title={content.title} />
        <div className="fixed-nav">
          <a className="home-link fixed-link" href="/">
            <img className="arrow-left" alt="arrow-left" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739104581/arrow_es4sej.png"></img>
            <span>home</span>
          </a>
        </div>
        <ToTop></ToTop>
        <section className="wrapper-container">
          <div className="row">
            <div className="col">
              <div className="vert-center">
              <h3>{content.title}</h3>
                {content.description.map((text, index) => {
                  return <p key={index}>{text}</p>
                })}
              
                {additionalLink}
              </div>
            </div>
            <div className="col with-image">
              <img alt="Final designs collage" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739105710/final-designs-collage_g30yoi.png"></img>
            </div>
          </div>

        </section>

        <section className="portfolio-content">{children}</section>

        <div className="project-bottom">
          <Link to={`/${nextProject}`} title="To recipebook">
            <span>cooking web application</span>
            <img className="arrow-right" alt="arrow-right" src="https://res.cloudinary.com/dkcdueneq/image/upload/v1739108219/arrow-right-long-light_1_qchclz.png"></img>
          </Link>
        </div>
      </div>
    </Layout>
  )
}

export default TemplateLayout
